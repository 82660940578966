<template>
  <div>
    <v-layout wrap justify-start pa-md-0>
      <v-flex xs12 align-self-center style="margin-all: 0px">
        <v-layout wrap justify-center>
          <v-flex
            xs12
            lg12
            :style="
              $vuetify.breakpoint.name == 'xs'
                ? 'margin-top:0%'
                : 'padding-top:0%'
            "
            align-self-center
          >
            <v-layout wrap>
              <v-flex xs12 align-self-center text-center>
                <span style="font-size: 24px; color: #000" class="poppinsbold">
                  Resource Centre
                </span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start>
              <v-flex xs12 text-left>
                <span
                  class="poppinssemibold"
                  style="color: #000000; font-size: 18px"
                  >Publications</span
                >
                <v-layout wrap justify-start pt-5>
                  <v-flex xs12 v-for="(item, i) in items" :key="i" pb-5>
                    <v-card outlined style="border: 1px solid">
                      <v-layout wrap justify-start>
                        <v-flex xs12>
                          <v-layout wrap class="hidden-sm-and-down">
                            <v-flex xs12 sm2 md2 lg1 xl1 text-left>
                              <v-img
                                :src="item.image"
                                :style="{
                                  height:
                                    $vuetify.breakpoint.name == 'md'
                                      ? '220px'
                                      : $vuetify.breakpoint.name == 'lg'
                                      ? '150px'
                                      : '250px',
                                }"
                              >
                              </v-img>
                            </v-flex>
                            <v-flex xs12 sm10 md10 pa-3>
                              <span
                                class="poppinssemibold"
                                style="color: #000000; font-size: 14px"
                                ><u>
                                  <a :href="item.link" target="_blank">
                                    {{ item.name }}</a
                                  ></u
                                >
                              </span>
                              <br />

                              <br />
                              <span
                                class="poppinsregular"
                                style="font-size: 14px; color: #000000"
                                >{{ item.text }}</span
                              >
                            </v-flex>
                          </v-layout>
                          <v-layout wrap class="hidden-md-and-up">
                            <v-flex xs12 sm6 text-left>
                              <v-img :src="item.image"> </v-img>
                            </v-flex>
                            <v-flex xs12 sm6 pa-3>
                              <span
                                class="poppinssemibold"
                                style="color: #000000; font-size: 14px"
                                ><u>
                                  <a :href="item.link" target="_blank">
                                    {{ item.name }}</a
                                  ></u
                                >
                              </span>
                              <br />

                              <br />
                              <span
                                class="poppinsregular"
                                style="font-size: 14px; color: #000000"
                                >{{ item.text }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start>
              <v-flex xs12>
                <v-select
                  outlined
                  label="Other Elephant Publications by WTI"
                  :items="other"
                  item-text="name"
                  item-value="link"
                  color="black"
                >
                  <template slot="item" slot-scope="other">
                    <v-layout
                      wrap
                      justify-start
                      @click="getLink(other.item.link)"
                    >
                      <v-flex xs12 text-left>
                        <div class="d-center">
                          <span
                            class="poppinsregular"
                            style="font-size: 14px; color: #000000"
                          >
                            {{ other.item.name }}</span
                          >
                        </div>
                      </v-flex>
                    </v-layout>
                  </template>
                </v-select>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          image: require("./../../assets/images/rc1.png"),
          name: "Right of Passage: Elephant Corridors of India [2nd Edition]",
          link: "https://www.wti.org.in/wp-content/uploads/2017/08/pub_right_of_passage-1.pdf",
          text: "This Conservation Reference Series publication brings together, in its second edition, a comprehensive listing of India’s 101 elephant corridors as listed and mapped by elephant experts in consultation with all state forest departments that are part of the elephant range in the country. Securing these corridors so that elephants and other species can locally migrate between habitats is crucial to their survival.",
        },
        {
          image: require("./../../assets/images/rc2.png"),
          name: "Right of Passage-2005",
          link: "https://www.wti.org.in/wp-content/uploads/2018/10/2005-right-of-passage.pdf",
          text: "The first edition of Right of Passage released in 2005 is a significant contribution to elephant conservation in India. The publication is an outcome of concerted efforts by Wildlife Trust of India, a number of acknowledged elephant experts, officials and NGO’s who identified 88 elephant corridors across India, detailing them in a Conservation Reference Series report.",
        },
        {
          image: require("./../../assets/images/rc3.png"),
          name: "Safe Passage, Safe Habitation – Securing the Thirunelli – Kudrakote Elephant Corridor through Voluntary Relocation",
          link: " https://www.wti.org.in/wp-content/uploads/2020/08/Safe-Passage-Safe-Habitation_WTI_Aug-2020.pdf",
          text: "This report is the result of WTI’s long term commitment to the Right of Passage for these majestic animals from land acquisition and voluntary relocation to the rehabilitation of families living in the Thirunelli-Kudrakote elephant corridor, a project initiated with the Kerala Government in 2005 with the support of Elephant Family, World Land Trust and IUCN Netherlands.",
        },
      ],
      other: [
        {
          name: "Conflict to Coexistence",
          value: "1",
          link: "https://www.wti.org.in/wp-content/uploads/2017/04/pub_conflict_coexistence-2.pdf",
        },
        {
          name: "Jumbo Express",
          value: "2",
          link: "https://wti.org.in/wp-content/uploads/2017/03/pub_jumbo_express.pdf",
        },
        {
          name: "Captive Concerns",
          value: "3",
          link: "https://www.wti.org.in/wp-content/uploads/2020/04/Captive-Concerns-2.pdf",
        },
        {
          name: "Living With Giants",
          value: "4",
          link: "https://wti.org.in/wp-content/uploads/2017/03/pub_living_with_giants.pdf",
        },
        {
          name: "Ganesha To Bin Laden",
          value: "5",
          link: "https://wti.org.in/wp-content/uploads/2017/03/pub_ganesha_bin-laden.pdf",
        },
        {
          name: "Elephants in Exile",
          value: "6",
          link: "https://wti.org.in/wp-content/uploads/2017/03/pub_elephants_in_exile.pdf",
        },
        {
          name: "Deadly Tracks",
          value: "7",
          link: "https://wti.org.in/wp-content/uploads/2017/03/pub_deadly_tracks.pdf",
        },
        {
          name: "Making Way",
          value: "8",
          link: "https://wti.org.in/wp-content/uploads/2017/03/pub_making_way.pdf",
        },
        {
          name: "Poisons and the Pachyderms",
          value: "9",
          link: "https://www.wti.org.in/wp-content/uploads/2017/03/pub_poisons_pachyderm.pdf",
        },
        {
          name: "Pakke Pachyderms",
          value: "10",
          link: "https://www.wti.org.in/wp-content/uploads/2017/03/pub_pakke_pachyderms-1.pdf",
        },
      ],
    };
  },
  methods: {
    getLink(link) {
      console.log("link", link);
      window.open(link);
    },
  },
};
</script>
