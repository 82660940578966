<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start pa-md-0>
      <v-flex xs12 align-self-center style="margin-all: 0px">
        <v-layout wrap justify-center>
          <v-flex
            xs12
            lg10
            :style="
              $vuetify.breakpoint.name == 'xs'
                ? 'margin-top:0%'
                : 'padding-top:0%'
            "
            align-self-center
          >
            <!-- <v-layout wrap class="hidden-md-and-up">
              <v-flex xs12 align-self-center text-left pa-4>
                <span style="font-size: 24px; color: #000" class="poppinsbold">
                  Tracks
                </span>
              </v-flex>
            </v-layout> -->
            <v-layout wrap>
              <v-flex xs12 align-self-center text-center>
                <span style="font-size: 24px; color: #000" class="poppinsbold">
                  Tracks
                </span>
              </v-flex>
            </v-layout>
            <v-layout wrap pa-4 justify-center>
              <v-flex xs12 md6 align-self-center text-center>
                <v-img
                  contain
                  :src="require('./../../assets/images/Tracks.png')"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#527E33"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-center py-0>
              <v-flex xs12 align-self-center>
                <v-card tile flat outlined color="#ffffff">
                  <v-layout wrap justify-center>
                    <v-flex xs12 md12 align-self-center>
                      <v-card flat tile color="transparent">
                        <v-layout wrap>
                          <v-flex xs12 align-self-center text-left pa-4>
                            <v-layout wrap>
                              <v-flex
                                xs12
                                align-self-center
                                text-left
                                class="hidden-md-and-up"
                              >
                                <span
                                  style="font-size: 24px; color: #000"
                                  class="poppinsbold"
                                >
                                  The Celebration: Taking ‘Gajah’ (Elephant) to
                                  the ‘Prajah’ (People)
                                </span>
                              </v-flex>
                              <v-flex
                                xs12
                                pt-3
                                align-self-center
                                text-center
                                class="hidden-sm-and-down"
                              >
                                <span
                                  style="font-size: 20px; color: #000"
                                  class="poppinsbold"
                                >
                                  The Celebration: Taking ‘Gajah’ (Elephant) to
                                  the ‘Prajah’ (People)
                                </span>
                              </v-flex>
                            </v-layout>
                            <span
                              class="poppinsregular"
                              :style="
                                $vuetify.breakpoint.name == 'xs'
                                  ? 'font-size:16px;color:#000'
                                  : 'font-size:20px;color:#000'
                              "
                            >
                              <span
                                :style="
                                  $vuetify.breakpoint.name == 'md'
                                    ? 'font-size:16px'
                                    : $vuetify.breakpoint.name == 'xs'
                                    ? 'font-size:16px'
                                    : 'font-size:16px'
                                "
                              >
                                All through history, elephants have been closely
                                associated with Indian culture, symbolism and
                                mythology. They are worshipped in religion for
                                their auspicious presence and revered for their
                                mighty strength. As a keystone species,
                                elephants also play a critical role in building
                                and maintaining the ecosystem that they and
                                other species, including us humans, are part of.

                                <br />
                                <br />

                                Yet in recent times, rising human-elephant
                                conflict is weakening the deep bond we share
                                with this magnificent animal. Gajotsavam aims to
                                revive and strengthen the cultural presence of
                                elephants in India, and to remind people -
                                across all sections - of the ecological and
                                economic significance of this species. At the
                                crux of the festivities is the idea of
                                co-existence, which will benefit both humans and
                                elephants.
                              </span>
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                    <v-flex
                      xs12
                      md12
                      align-self-center
                      pa-4
                      class="hidden-md-and-up"
                    >
                      <v-img
                        height="600px"
                        :src="require('./../../assets/images/worship.jpg')"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#527E33"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <span
                        style="
                          color: #6a768a;
                          font-style: italic;
                          font-size: 11px;
                        "
                        class="poppinsbold"
                      >
                        Ganesha, the elephant-headed God</span
                      >
                    </v-flex>
                    <v-flex
                      xs12
                      sm6
                      md6
                      align-self-center
                      pa-4
                      class="hidden-sm-and-down"
                    >
                      <v-img
                        contain
                        height="400px"
                        :src="require('./../../assets/images/worship.jpg')"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#527E33"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <span
                        style="
                          color: #6a768a;
                          font-style: italic;
                          font-size: 11px;
                        "
                        class="poppinsbold"
                      >
                        Ganesha, the elephant-headed God</span
                      >
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-layout wrap justify-center>
                  <v-flex xs12 md12>
                    <v-card flat tile color="transparent">
                      <v-layout wrap>
                        <v-flex xs12 align-self-center text-left pa-4>
                          <v-layout wrap class="hidden-md-and-up">
                            <v-flex xs12 text-left>
                              <span
                                style="font-size: 24px; color: #000"
                                class="poppinsbold"
                              >
                                The conflict
                              </span></v-flex
                            >
                          </v-layout>
                          <v-layout
                            wrap
                            justify-center
                            class="hidden-sm-and-down"
                          >
                            <v-flex xs12 text-center>
                              <span
                                style="font-size: 20px; color: #000"
                                class="poppinsbold"
                              >
                                The conflict
                              </span></v-flex
                            >
                          </v-layout>
                          <span
                            class="poppinsregular"
                            :style="
                              $vuetify.breakpoint.name == 'xs'
                                ? 'font-size:17px;color:#000'
                                : 'font-size:19px;color:#000'
                            "
                          >
                            <span
                              :style="
                                $vuetify.breakpoint.name == 'md'
                                  ? 'font-size:14px'
                                  : 'font-size:16px'
                              "
                            >
                              India is home to an estimated 27,000 Asian
                              elephants. They live in herds led by wise, old
                              matriarchs and seasonally migrate long distances
                              in search of food and water. The migratory routes
                              and corridors that lead them from one feeding
                              ground to another remain deeply etched in their
                              memories.

                              <br />
                              <br />
                              Sadly, these ancient migratory routes are being
                              steadily destroyed and fractured in the name of
                              development. As the wildlife habitats disappear,
                              the pachyderms are forced to venture into human
                              habitations and croplands, for food and water. The
                              tragic result of this is loss of lives (both human
                              and elephants) and damage to crops and property.
                            </span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 md6 text-center pa-4>
                    <v-img
                      contain
                      :src="require('./../../assets/images/gj2.jpg')"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="#527E33"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <p
                      style="
                        color: #6a768a;
                        font-style: italic;
                        font-size: 11px;
                        line-height: normal;
                      "
                      class="poppinsbold pa-1"
                    >
                      Elephant death due to electrocution. Flowers and incense
                      on and around the body are evidence of the cultural
                      significance of elephants. | Photo: Samshul Ali
                    </p>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-center pt-0 pb-0>
              <v-flex xs12 align-self-center pt-0>
                <v-layout wrap justify-center>
                  <v-flex xs12 md12 align-self-center>
                    <v-layout wrap>
                      <v-flex xs12 align-self-center text-left pa-4>
                        <span
                          class="poppinsregular"
                          :style="
                            $vuetify.breakpoint.name == 'xs'
                              ? 'font-size:16px;color:#000'
                              : 'font-size:20px;color:#000'
                          "
                        >
                          <span
                            :style="
                              $vuetify.breakpoint.name == 'md'
                                ? 'font-size:16px'
                                : $vuetify.breakpoint.name == 'xs'
                                ? 'font-size:16px'
                                : 'font-size:16px'
                            "
                          >
                            While the loss to human life and property is
                            apparent, elephants too are suffering significantly.
                            Electrocution - from power fences set up to prevent
                            elephants from entering crop fields - is the leading
                            cause of their deaths in India. While some of these
                            incidents are accidental, many elephants are
                            deliberately and brutally killed to avenge damaged
                            farms and properties. A while ago, the poisoned
                            carcass of a sub adult tusker was found in a crop
                            field in Tezpur, Assam. The words “Paddy Thief (Bin)
                            Laden” were scribbled on the body in Assamese. The
                            crude slogan comparing the elephant with a hated
                            symbol of the times, Bin Laden, is just one proof of
                            the increasing intolerance towards elephants.
                            <br />
                          </span>
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 md6 align-self-center class="hidden-sm-and-down">
                    <v-img
                      contain
                      :src="require('./../../assets/images/eleph.jpg')"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="#527E33"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <p
                      style="
                        color: #6a768a;
                        font-style: italic;
                        font-size: 11px;
                        line-height: normal;
                      "
                      class="poppinsbold pa-1"
                    >
                      Sub-adult tusker poisoned in a crop field of Holleswar
                      village. The words "Paddy Thief (Bin) Laden" scrawled on
                      its body in Assamese language. | Photo: Prabal Sarkar
                    </p>
                  </v-flex>
                  <v-flex
                    xs12
                    align-self-center
                    pa-4
                    text-center
                    class="hidden-md-and-up"
                  >
                    <v-img
                      contain
                      :src="require('./../../assets/images/eleph.jpg')"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="#527E33"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <p
                      style="
                        color: #6a768a;
                        font-style: italic;
                        font-size: 11px;
                        line-height: normal;
                      "
                      class="poppinsbold pa-1"
                    >
                      Sub-adult tusker poisoned in a crop field of Holleswar
                      village. The words "Paddy Thief (Bin) Laden" scrawled on
                      its body in Assamese language | Photo: Prabal Sarkar
                    </p>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center pt-0 pb-0>
              <v-flex xs12 align-self-center pt-0>
                <v-card tile flat outlined color="#ffffff">
                  <v-layout wrap justify-center>
                    <v-flex xs12 md12 align-self-center text-left pa-4>
                      <span
                        class="poppinsregular"
                        :style="
                          $vuetify.breakpoint.name == 'xs'
                            ? 'font-size:16px;color:#000'
                            : 'font-size:20px;color:#000'
                        "
                      >
                        <span
                          :style="
                            $vuetify.breakpoint.name == 'md'
                              ? 'font-size:16px'
                              : $vuetify.breakpoint.name == 'xs'
                              ? 'font-size:16px'
                              : 'font-size:16px'
                          "
                        >
                          In areas of high conflict, the use of firecrackers to
                          frighten herds has become a horrifying and appalling
                          form of entertainment. In January this year, a tusker
                          was burnt alive in one such incident.
                          <br />
                        </span>
                      </span>
                    </v-flex>
                    <v-flex xs12 md12 align-self-center>
                      <v-card flat tile color="transparent">
                        <v-layout wrap justify-center>
                          <v-flex
                            xs12
                            md6
                            align-self-center
                            text-center
                            pa-4
                            pa-md-0
                            pa-lg-0
                            pa-xl-0
                          >
                            <v-img
                              contain
                              :src="
                                require('./../../assets/images/conflict.jpg')
                              "
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="#527E33"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                            <p
                              style="
                                color: #6a768a;
                                font-style: italic;
                                font-size: 11px;
                                line-height: normal;
                              "
                              class="poppinsbold pa-1"
                            >
                              Use of firecrackers to torment elephants further
                              agitates them, leaving a long-term negative impact
                              on their behaviour. | Photo: Biplab Hazra
                            </p>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-start pa-md-4 pa-lg-0>
              <v-flex xs12 align-self-center>
                <v-card tile flat>
                  <v-layout wrap justify-center>
                    <v-flex xs12 align-self-center text-left pa-4>
                      <v-layout wrap justify-center class="hidden-md-and-up">
                        <v-flex xs12 text-left>
                          <span
                            style="font-size: 24px; color: black"
                            class="poppinsbold"
                          >
                            Conflict to co-existence
                          </span></v-flex
                        >
                      </v-layout>
                      <v-layout wrap justify-center class="hidden-sm-and-down">
                        <v-flex xs12 text-center>
                          <span
                            style="font-size: 20px; color: black"
                            class="poppinsbold"
                          >
                            Conflict to co-existence
                          </span></v-flex
                        >
                      </v-layout>
                      <span
                        style="text-align: justify"
                        class="poppinsregular"
                        :style="
                          $vuetify.breakpoint.name == 'xs'
                            ? 'font-size:17px;color:#000'
                            : 'font-size:19px;color:#000'
                        "
                      >
                        <span
                          :style="
                            $vuetify.breakpoint.name == 'md'
                              ? 'font-size:16px'
                              : 'font-size:16px'
                          "
                        >
                          At WTI, we have identified 101 elephant corridors
                          across India as part of the national elephant corridor
                          project –<a
                            href="https://www.wti.org.in/projects/right-of-passage-national-elephant-corridors-project/"
                            target="_blank"
                            ><span style="color: #03a5fc">
                              <u> <b> Right of Passage. </b></u></span
                            ></a
                          >
                          <br />
                          <br />
                          Elephant corridors are narrow strips of land used by
                          elephants and other wildlife to access the fragmented
                          forest patches. It is very important to protect the
                          designated corridors, and this is exactly what the
                          Right of Passage project is aiming for.
                        </span>
                      </span>
                    </v-flex>

                    <v-layout wrap justify-center>
                      <v-flex
                        xs12
                        sm12
                        md6
                        pa-4
                        pa-md-0
                        pa-lg-0
                        pa-xl-0
                        text-center
                      >
                        <v-img
                          :src="require('./../../assets/images/Ele2.jpg')"
                          contain
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#527E33"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <p
                          style="
                            color: #6a768a;
                            font-style: italic;
                            font-size: 11px;
                            line-height: normal;
                          "
                          class="poppinsbold pa-1"
                        >
                          Mother elephant and her calf photographed next to our
                          signage installed at the Thirunelli-Kudrakote elephant
                          corridor in Wayanad, Kerala. | Photo: Ramith M
                        </p>
                      </v-flex>
                    </v-layout>
                    <v-flex xs12 align-self-center pa-4 text-left>
                      <span
                        class="poppinsregular"
                        :style="
                          $vuetify.breakpoint.name == 'xs'
                            ? 'font-size:17px;color:#000'
                            : 'font-size:19px;color:#000'
                        "
                      >
                        <span
                          :style="
                            $vuetify.breakpoint.name == 'md'
                              ? 'font-size:16px'
                              : 'font-size:16px'
                          "
                        >
                          Corridor monitoring is one of the essential components
                          of the project for which we are working with a cadre
                          of Green Corridor Champions (or GCCs). These are
                          community-based organisations or groups of individuals
                          who are the eyes, ears and voice of corridors. They
                          monitor animal movement through designated corridors;
                          work with local, state, and central governments to
                          institutionalise corridor protection; sensitise the
                          local community and tactfully dissuade them from
                          activities that can result in land use changes.
                          <span class="poppinsbold"
                            >With effective monitoring and tracking, casualties
                            in areas of our work have reduced in the last few
                            years.
                          </span>
                          <br />
                          <br />
                          At present, 13 active GCCs are deployed, covering 30
                          corridors. We need nearly 30 more to be trained,
                          equipped and established for effective corridor
                          monitoring.
                        </span>
                      </span>
                    </v-flex>
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm12 md6 pa-4>
                        <v-img :src="require('./../../assets/images/gj1.jpg')">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#527E33"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <span
                          style="
                            color: #6a768a;
                            font-style: italic;
                            font-size: 11px;
                          "
                          class="poppinsbold"
                        >
                          Team GCC | Photo:WTI</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex xs12 align-self-center>
                <v-layout wrap justify-center>
                  <v-flex xs12 text-left>
                    <v-card flat tile>
                      <v-layout wrap>
                        <v-flex
                          xs12
                          align-self-center
                          text-xs-center
                          text-sm-justify
                          pa-4
                        >
                          <span
                            class="poppinsregular"
                            :style="
                              $vuetify.breakpoint.name == 'xs'
                                ? 'font-size:16px;color:#000000'
                                : 'font-size:20px;color:#000000'
                            "
                          >
                            <v-layout wrap class="hidden-md-and-up">
                              <v-flex xs12 align-self-center text-left pb-2>
                                <span
                                  style="font-size: 24px"
                                  class="poppinsbold"
                                >
                                  The Appeal
                                </span>
                              </v-flex>
                            </v-layout>
                            <v-layout wrap class="hidden-sm-and-down">
                              <v-flex xs12 align-self-center text-center pb-2>
                                <span
                                  style="font-size: 20px"
                                  class="poppinsbold"
                                >
                                  The Appeal
                                </span>
                              </v-flex>
                            </v-layout>

                            <span style="color: #000000; font-size: 16px">
                              When left undisturbed, with their passage
                              unobstructed, elephants truly are the gentle
                              giants. A lot needs to be done to provide safer
                              spaces to this magnificent species. We owe this to
                              our national heritage animal and its future
                              generations, and also to our own.
                            </span>

                            <br />
                            <br />
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-start pt-2 pb-6 pa-md-4 pa-lg-0 py-lg-4>
              <v-flex xs12 align-self-center>
                <v-card
                  outlined
                  flat
                  tile
                  color="#ffffff"
                  style="border: 2px solid #527e33"
                >
                  <v-layout wrap pa-2>
                    <v-flex xs12 align-self-center text-center pa-2>
                      <span class="poppinssemibold">
                        Your support today will strengthen our efforts to
                        provide Right of Passage to elephants and promote their
                        co-existence with humans.
                      </span>
                    </v-flex>

                    <v-flex xs12 align-self-center text-center pa-2>
                      <v-btn
                        tile
                        large
                        dark
                        color="#e27725"
                        @click="
                          $vuetify.breakpoint.name == 'xs' ||
                          $vuetify.breakpoint.name == 'sm'
                            ? scrollMeXS()
                            : scrollMe()
                        "
                      >
                        <span
                          class="poppinsbold"
                          :style="
                            $vuetify.breakpoint.name == 'xs'
                              ? 'font-size:17px'
                              : 'font-size:24px'
                          "
                        >
                          HELP ASIAN ELEPHANTS
                        </span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
<div>
      <ResourceCenter/>
    </div>
            <v-layout
              wrap
              justify-start
              pt-2
              pb-6
              pa-md-4
              pa-lg-0
              py-lg-4
              pb-lg-8
            >
              <v-flex xs12 align-self-center>
                <v-card outlined flat tile color="#527E33">
                  <v-layout wrap pa-2>
                    <v-flex xs12 sm3 align-self-center text-center pa-2>
                      <v-img
                        contain
                        height="100px"
                        :src="require('./../../assets/images/wtilogo.png')"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#527E33"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-flex>

                    <v-flex
                      xs12
                      sm9
                      align-self-center
                      text-sm-left
                      text-xs-center
                      pa-2
                      style="color: #ffffff"
                    >
                      <span class="poppinsbold">
                        FOR A SECURE NATURAL HERITAGE OF INDIA
                      </span>
                      <br />
                      <br />
                      <span class="poppinssemibold">
                        CONTACT INFO
                        <br />
                        <span class="poppinsregular">
                          Address: F-13, Sector 8, National Capital Region
                          (NCR), Noida 201301, India Email: info@wti.org.in
                        </span>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import ResourceCenter from "./resourceCenter"
export default {
   components: {
    ResourceCenter
  }, 
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
    };
  },
  methods: {
    scrollMe() {
      // document.body.scrollTop = 0;
      // document.documentElement.scrollTop = 0;

      document.getElementById("donateNow").scrollIntoView({
        behavior: "smooth",
      });
    },
    scrollMeXS() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      document.getElementById("donateNow").scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped>
.grad {
  background-image: linear-gradient(180deg, red, yellow);
}
.bannerGrad {
  background: transparent;

  background-image: linear-gradient(
    104deg,
    #bc4639 6%,
    #7e7e7e2a 94%,
    transparent 100%
  );
}
.rot {
  animation-duration: 3s;
  animation-name: slidein;
}
@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
.texth {
  line-height: 1.5em;
  min-height: 9.5em;
  float: left;
  width: 100%;
}
.hovCls {
  color: #005f32;
  border-bottom: 3px solid #005f32;
}

.hovCls:hover {
  color: blue;
  border-bottom: 3px solid blue;
}
</style>
